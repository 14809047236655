export const URL_PARENT_DASHBOARD = "/app/"
export const URL_LOGIN = "/login/"
export const URL_HELP_CENTER =
  "https://learnandearnsupport.atlassian.net/servicedesk/customer/portals"
export const URL_HOME = "/"
export const URL_APP_LOGIN = "https://app.learnandearn.com/login/"
export const URL_TWITTER = "https://twitter.com/learnearnapp"
export const URL_INSTAGRAM = "https://www.instagram.com/learnearnapp"
export const URL_FACEBOOK = "https://www.facebook.com/learnearnapp"
export const URL_APPSTORE_GOOGLE =
  "https://play.google.com/store/apps/details?id=com.antmoney.learnandearn"
export const URL_APPSTORE_APPLE =
  "https://apps.apple.com/us/app/learn-earn-early-investing/id1511029867"
export const URL_PLEDGE_REGISTER = "/pledge/register/"
export const URL_PARENT_REGISTRATION = "/parent-registration/"
export const URL_TOS =
  "https://learnandearnsupport.atlassian.net/servicedesk/customer/portal/2/topic/7a83c556-8146-463d-8bfc-3ee24684b691/article/33292337"
export const URL_PRIVACY_POLICY =
  "https://learnandearnsupport.atlassian.net/servicedesk/customer/portal/2/topic/94325f84-c9ba-4552-a7a6-96c9e04998f9/article/33423361"
export const URL_LEGAL_DISCLAIMERS =
  "https://learnandearnsupport.atlassian.net/servicedesk/customer/portal/2/topic/88d2bfa8-324b-4cff-aa56-049f87c26e99/article/322764827"
export const URL_DONT_SELL_PERSONAL_INFO =
  "https://learnandearnsupport.atlassian.net/servicedesk/customer/portal/2/article/326697005"

export const URL_OAUTH_ACCOUNT_LINK_RETURN_URL = `${process.env.URL}/app/linked-account/`

export const URL_LEARNANDEARN_PAGE_LINK = "https://learnandearnapp.page.link"
