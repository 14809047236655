import * as progress from "@services/progress"
import * as headers from "@data/headers"
import axios from "axios"

const isClient = typeof window !== "undefined"

export const init = () => {
  let { pathname } = window.location
  let { beneficiaryToken, elsi } = window.localStorage
  let nextPage = progress.getNext()

  beneficiaryToken =
    typeof beneficiaryToken === `undefined` ? null : beneficiaryToken

  elsi = typeof elsi === `undefined` ? null : elsi

  const is_app_path =
    !pathname.includes("/app-navigator") &&
    (pathname.includes("/app/") || pathname.includes("/app"))

  if (isClient && is_app_path && !elsi) {
    window.location = "/login"
  }

  if (elsi) {
    let validTokenQuery = {
      query: `query {
          user {
            id
            userType
          }
        }
        `,
    }

    const options = {
      method: "POST",
      data: JSON.stringify(validTokenQuery),
      headers: headers.getHeaders(),
      url: headers.api,
    }

    const onError = () => {
      if (is_app_path) {
        localStorage.clear()
        sessionStorage.clear()
        window.location = "/login"
      }
    }

    axios(options)
      .then((res) => {
        if (res.data.errors) {
          onError()
        } 
      })
      .catch((error) => {
        onError()
      })
  }

  /*
    If the user has no registration token
    trying to access /pledge/register/* (registration)
    that is not select-support or link-your-bank
    and does not have a session token
    they are redirected to the homepage
  */

  /*
    If the user has a registration token
    and has started registration
    and they access /pledge/register/ (parent registration start)
    they are redirected to the page they left off of
  */
  if (
    pathname === "/pledge/register/" &&
    beneficiaryToken &&
    nextPage !== "register"
  ) {
    window.location = "/pledge/register/" + nextPage
  }

  /*
      If the user has started an account with a valid session token
      and is accessing registration start /pledge/register/ (parent registration start)
      and ...
    */
  if (pathname === "/pledge/register/" && !beneficiaryToken && elsi) {
    if (nextPage === "register") {
      window.location = "/pledge/register/your-info"
    } else {
      window.location = "/pledge/register/" + nextPage
    }
  }
}
