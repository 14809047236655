/* Implement Gatsby's Browser APIs in this file, see: https://www.gatsbyjs.org/docs/browser-apis/ */
const interceptor = require("@services/interceptor")
const LAEJS = require("@services/LAEJS")

const dbg = require("debug")
if (process.env.DEBUG) {
  dbg.enable(process.env.DEBUG)
  dbg.log = console.log.bind(console)
} else {
  dbg.disable()
}

window.LAEJS =
  typeof LAEJS.default === "function" ? LAEJS.default() : LAEJS.default

exports.onClientEntry = () => {
  // console.log("We've started!")
}

exports.onInitialClientRender = () => {
  // console.log("onInitialClientRender")
}

exports.onPostPrefetchPathname = () => {
  // console.log("onPostPrefetchPathname")
}

exports.onPreRouteUpdate = () => {
  // console.log("onPreRouteUpdate")
}

exports.onPrefetchPathname = () => {
  // console.log("onPrefetchPathname")
}

exports.onRouteUpdate = () => {
  // console.log("onRouteUpdate")
  if (typeof window !== `undefined`) {
    let intercept = interceptor.init
    intercept()
  }
}

exports.onRouteUpdateDelayed = () => {
  // console.log("onRouteUpdateDelayed")
}
