import {
  TOKEN_BENEFICIARY,
  PARENT_REGISTRATION_STEP_INVITE,
  PARENT_REGISTRATION_COMPLETED_STEP,
  PARENT_REGISTRATION_LS_KEY_INITIAL_DEPOSIT
} from "@data/constants"
//const debug = require("debug")("lae:" + __filename)
const isClient = typeof window !== "undefined"

let pageArr = [
  //pages in order of reg flow sequence
  "register",
  "parent-account",
  "your-info",
  "personal-info",
  "child-info",
  "almost-there",
  "employment",
  "verify-statements",
  "confirm-child-info",
  "review-application",
  "select-support",
  "link-your-bank"
]

if (
  isClient &&
  typeof window.localStorage.getItem(TOKEN_BENEFICIARY) !== "string"
) {
  pageArr.push(PARENT_REGISTRATION_STEP_INVITE)
}

export const hasInvite = pageArr.includes(PARENT_REGISTRATION_STEP_INVITE)

export const getNext = () => {
  //determines which should be the next page after user completes current form
  if (typeof window !== "undefined") {
    let currentPageArr = window.location.pathname.split("/"),
      currentPage = currentPageArr[currentPageArr.length - 1],
      currentPageIndex = pageArr.indexOf(currentPage),
      progress = localStorage.getItem(PARENT_REGISTRATION_COMPLETED_STEP),
      progressIndex = pageArr.indexOf(progress),
      next
    if (progressIndex > currentPageIndex) {
      next = pageArr[progressIndex + 1]
    } else {
      next = pageArr[currentPageIndex + 1]
    }
    return next
  }
}

export const updateProgress = () => {
  //Will update the users status if they have progressed further than before
  if (typeof window !== "undefined") {
    let path = window.location.pathname
    let last = path.slice(path.length - 1, path.length)
    if (last === "/") path = path.slice(0, path.length - 1)
    let pathArr = path.split("/"),
      pathSegment = pathArr[pathArr.length - 1],
      pathSegmentIndex = pageArr.indexOf(pathSegment)

    let lastRecordedPage = localStorage.getItem(
        PARENT_REGISTRATION_COMPLETED_STEP
      ),
      lastRecordIndex = pageArr.indexOf(lastRecordedPage)

    if (pathSegmentIndex > lastRecordIndex)
      localStorage.setItem(PARENT_REGISTRATION_COMPLETED_STEP, pathSegment)
  }
}

export const regComplete = () => {
  localStorage.removeItem("broker")
  localStorage.removeItem("childAddress")
  localStorage.removeItem("childDob")
  localStorage.removeItem("childEmail")
  localStorage.removeItem("childName")
  localStorage.removeItem("directorOf")
  localStorage.removeItem("employmentStatus")
  localStorage.removeItem("parentAddress")
  localStorage.removeItem("parentEmail")
  localStorage.removeItem("parentsDob")
  localStorage.removeItem("parentsName")
  localStorage.removeItem("parentsPhone")
  localStorage.removeItem("politicallyExposedNames")
  localStorage.removeItem("stock")
  localStorage.removeItem(PARENT_REGISTRATION_LS_KEY_INITIAL_DEPOSIT)
  sessionStorage.setItem("firstVisit", "true")
  setTimeout(function() {
    if (isClient) {
      window.location = `/app`
    }
  }, 3000)
}

export const progressBar = () => {
  if (typeof window !== "undefined") {
    let path = window.location.pathname
    let last = path.slice(path.length - 1, path.length)
    if (last === "/") path = path.slice(0, path.length - 1)
    let pathArr = path.split("/"),
      pathSegment = pathArr[pathArr.length - 1],
      pathSegmentIndex = pageArr.indexOf(pathSegment),
      total

    total = ((pathSegmentIndex + 1) / pageArr.length) * 100
    let width = total + "%"

    return width
  }
}
