import {
  osVersion,
  osName,
  fullBrowserVersion,
  browserName,
} from "react-device-detect"
import { v4 as uuidv4 } from "uuid"
import { TOKEN_SESSION_ID } from "./constants"
import LAEJS from "@services/LAEJS"

const isClient = typeof window !== "undefined"

const hardware = typeof window !== "undefined" ? window.navigator.platform : ""
const urlAddress = typeof window !== "undefined" ? window.location.href : ""
const vendor = typeof window !== "undefined" ? window.navigator.vendor : ""
const appName = "Learn and Earn Parent Portal"
const fullBrowserName = vendor
  ? `${window.navigator.vendor} - ${browserName}`
  : browserName

export const getHeaders = () => {
  const token = isClient ? window.localStorage.getItem("elsi") : ""
  const timestamp = Math.round(Date.now() / 1000)
  const session_id = isClient
    ? window.localStorage.getItem(TOKEN_SESSION_ID) || ""
    : ""
  return {
    "Content-Type": "application/json",
    Accept: "application/json",
    "X-Client-App": `${appName} - ${urlAddress}`,
    "X-Client-Build": "1.0",
    "X-Client-Hardware": hardware,
    "X-Client-Os": `${osName} - ${osVersion}`,
    "X-Client-Platform": LAEJS.isWebView ? "app-webview" : "web",
    "X-Client-Udid": "",
    "X-Client-Browser": fullBrowserName,
    "X-Client-Browser-Version": fullBrowserVersion,
    "X-Version": "1.0",
    "X-Request-Id": uuidv4(),
    "X-Request-Timestamp": timestamp,
    "X-Session-Id": session_id,
    AuthorizationToken: token,
  }
}
export const api = process.env.API_URL
