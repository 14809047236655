/**
 * This is the native app javascript interface.
 * It will help facilitate bi-directional communication between webviews and native apps.
 */
import { TOKEN_SESSION_EXP, TOKEN_SESSION_ID } from "@data/constants"
import { validate } from "@data/validations"
import { ILAEJSDispatch } from "@interfaces"
import { LAEJSTrackingExtraParam, LAEJSJSONShareType } from "@types"
import _ from "lodash"
import * as rxjs from "rxjs"

declare global {
  interface Window {
    webkit: any
    JSI: any
  }
}

interface ISessionData {
  session_id: string
  session_expires_at: string | number
}

const isClient = typeof window !== "undefined"
const url_params = isClient ? new URLSearchParams(window.location.search) : null

const _isAndroid =
  (isClient && window.JSI && typeof window.JSI === "object") ||
  (url_params && url_params.get("__isAndroid"))
    ? true
    : false

const _isIOS =
  (isClient &&
    window.webkit &&
    window.webkit.messageHandlers &&
    typeof window.webkit.messageHandlers === "object") ||
  (url_params && url_params.get("__isIOS"))
    ? true
    : false

const _isWebView = _isAndroid || _isIOS

const _isObject = (obj: any) => {
  return obj === Object(obj)
}

const _ensureIsObject = (objOrJSON: ILAEJSDispatch | undefined | string) => {
  let params = null

  if (_isObject(objOrJSON)) {
    params = objOrJSON
  } else if (typeof objOrJSON === "string") {
    try {
      params = JSON.parse(objOrJSON)
    } catch (e) {
      throw new Error("Invalid JSON passed in.")
    }
  }

  return params
}

const pageEventTypes = ["jsOnNativeBackPressed", "jsSetWebViewSession"]
const _pageEvents: any = {}
pageEventTypes.forEach((event) => (_pageEvents[event] = new rxjs.Subject()))

/**
 * General dispatch method to call iOS or Android native code.
 *
 * @param {string} method Name of the JS interface method.
 * @param {object|string} params key/value pair object, or JSON string, of data to send to the JS interface method.
 */
const _dispatch = (method: string, params?: ILAEJSDispatch | string) => {
  let return_value = false
  const parsedParams = _ensureIsObject(params)

  if (typeof method !== "string" || !method) {
    throw new Error("name: is not a string")
  }

  let jsonPost = parsedParams && parsedParams.json ? parsedParams.json : "{}"
  jsonPost = JSON.stringify(jsonPost)

  try {
    if (_isAndroid && isClient && window.JSI[method]) {
      window.JSI[method](jsonPost)
      return_value = true
    } else if (_isIOS && isClient && window.webkit.messageHandlers[method]) {
      window.webkit.messageHandlers[method].postMessage(jsonPost)
      return_value = true
    } else {
      console.log("did not dispatch")
    }
  } catch (e) {
    console.log(e)
  }

  // only if an object was passed in
  if (typeof params === "object" && typeof params.done === "function") {
    let arg = {
      dispatched: return_value,
    }
    params.done(arg)
  }

  return return_value
}

const _parseJson = (json: string, done: (json: any) => void) => {
  try {
    const data = JSON.parse(json)
    if (typeof done === "function") {
      done(data)
    }
  } catch (e) {
    console.log(e)
  }
}

/**
 * This method is used to trigger a native app call with the typical method name/json object params.
 *
 * @param eventType string The name of the Native App method to call.
 * @returns function will return a function that takes in the name of the methodType to call, and an optional object that will be sent as JSON.
 *
 */
const _basicDispatchFactory = (eventType: string) => {
  return (params: ILAEJSDispatch) => {
    return _dispatch(eventType, params)
  }
}

const LAEJS: { [key: string]: any } = {
  isIOS: _isIOS,
  isAndroid: _isAndroid,
  isWebView: _isWebView,

  /**
   *
   * @param event string The name of the event you are listening to
   * @param action function the callback to use
   */
  on: (event: string, action: (data: any) => void) => {
    if (typeof _pageEvents[event] !== "undefined") {
      _pageEvents[event].subscribe((v: any) => action(v))
    } else {
      const events = Object.keys(_pageEvents).join(`.\n`)
      throw `LAEJS: event '${event}' is not defined.\nAllowable events:\n${events}`
    }
  },
}

// ==========================================================================
// Mainly coming FROM native app
// ==========================================================================

LAEJS.jsOnNativeBackPressed = (json: string) => {
  _parseJson(json, (data) => {
    _pageEvents.jsOnNativeBackPressed.next(data)
  })
}

LAEJS.jsSetWebViewSession = (session_json: string) => {
  try {
    const session: ISessionData = JSON.parse(session_json)

    if (
      session &&
      _.isPlainObject(session) &&
      session.session_id &&
      session.session_expires_at
    ) {
      localStorage.setItem(TOKEN_SESSION_ID, session.session_id)
      localStorage.setItem(
        TOKEN_SESSION_EXP,
        String(session.session_expires_at)
      )
      _pageEvents.jsSetWebViewSession.next(session)
    }
  } catch (e) {
    console.log(e)
  }
}

// ==========================================================================
// Mainly sent TO native app by web app
// ==========================================================================

LAEJS.jsOnShare = (params: ILAEJSDispatch) => {
  const jsonPost =
    params && params.json
      ? <LAEJSJSONShareType>params.json
      : { text: null, url: null }

  const text = jsonPost.text
  const url = jsonPost.url
  const title = jsonPost.title

  if (typeof text !== "string" || !text) {
    throw new Error("text: Is not a string")
  }

  if (typeof url !== "string" || !url || !validate.url.test(url)) {
    throw new Error("url: Is not a valid URL.")
  }

  if (title && typeof title !== "string") {
    throw new Error("title: Is not a string.")
  }

  return _dispatch("jsOnShare", params)
}

LAEJS.jsOnShareComplete = (params: ILAEJSDispatch) => {
  const dispatched = _dispatch("jsOnShareComplete")

  if (params && typeof params.done === "function") {
    params.done({ dispatched })
  } else if (
    params.done &&
    typeof params.done === "string" &&
    validate.url.test(params.done)
  ) {
    if (isClient) {
      window.location.href = params.done
    }
  }

  return dispatched
}

LAEJS.track = (
  eventName: string,
  eventTimestamp: number = 0,
  eventData: LAEJSTrackingExtraParam[] | null = null
) => {
  if (typeof eventName !== "string") {
    throw "eventName: is not a string"
  }
  if (typeof eventTimestamp !== "number" || !eventTimestamp) {
    eventTimestamp = Date.now()
  }

  const paramsData: LAEJSTrackingExtraParam[] | null = eventData

  const params: ILAEJSDispatch = {
    json: {
      eventToken: eventName,
      eventTimestamp: eventTimestamp,
      params: paramsData || [],
    },
  }

  _dispatch("jsTrackLEAnalyticEvent", params)
}

// generate the basic dispatch events.
const simpleDispatchEvents = [
  "jsOnBackPressed",
  "jsOnGetWebViewSession",
  "jsOnLogout",
  "jsOnRegistrationComplete",
  "jsOnTokenInvalid",
  "jsSetUserIsCustodian",
  "jsTrackAdjustEvent",
  "jsShowLoading",
  "jsHideLoading",
]

simpleDispatchEvents.forEach(
  (event) => (LAEJS[event] = _basicDispatchFactory(event))
)

export default LAEJS
